<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div>
    <el-button type="primary" @click="show">主要按钮</el-button>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { areaList } from '../../utils/area-data'
import { saveArea } from '../../api/jcxx'
export default {
  data() {
    return {
      areaList: areaList,
      provinces: [],
      cities: [],
      areas: [],
    }
  },
  created() {},
  methods: {
    show() {
      var p = Object.getOwnPropertyNames(this.areaList.province_list)
      for (var i = 0; i < p.length - 1; i++) {
        this.provinces.push({
          id: i + 1 + '',
          provinceid: p[i],
          province: this.areaList.province_list[p[i]],
        })
      }
      var c = Object.getOwnPropertyNames(this.areaList.city_list)
      for (var j = 0; j < c.length - 1; j++) {
        this.cities.push({
          id: j + 1 + '',
          cityid: c[j],
          city: this.areaList.city_list[c[j]],
          provinceid: c[j].substring(0, 2) + '0000',
        })
      }
      var a = Object.getOwnPropertyNames(this.areaList.county_list)
      for (var z = 0; z < a.length - 1; z++) {
        this.areas.push({
          id: z + 1 + '',
          areaid: a[z],
          area: this.areaList.county_list[a[z]],
          cityid: a[z].substring(0, 4) + '00',
        })
      }
      saveArea({
        provinces: this.provinces,
        areas: this.areas,
        cities: this.cities,
      }).then((res) => {
        if (res.code === 200) {
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style>
</style>
